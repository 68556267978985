// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Handler = (data?: any) => void;

const EVENT_PREFIX = 'docsm';

const handlers: {
  [topic: string]: Handler[];
} = {};

export const emit = (
  topic: string,
  data?: { [key: string]: unknown },
): void => {
  document.dispatchEvent(
    new CustomEvent(`${EVENT_PREFIX}:${topic}`, { detail: data }),
  );
};

export const on = (topic: string, handler: Handler): void => {
  handlers[topic] = handlers[topic] || [];
  handlers[topic].push(handler);
  document.addEventListener(
    `${EVENT_PREFIX}:${topic}`,
    (e) => {
      const event = e as CustomEvent;
      handler(event.detail);
    },
    false,
  );
};
