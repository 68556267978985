import React from "react";
import { AbstractStep, UploadApi, UploadData } from "./upload-types";
import { Result } from "../components/upload/HomeResult";

export class HomeResultStep extends AbstractStep {
    render(submitButtonCallback: Function,
           showOverwriteToggle: boolean,
           data: UploadData,
           api: UploadApi): JSX.Element {
        return <Result {...api} data={data}/>;
    }
}

export const homeResultStep = new HomeResultStep(
    'info',
    'Ergebnis',
    'Ergebnis des Imports',
    undefined
);
