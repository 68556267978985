import { Hub } from "aws-amplify";
import { EffectCallback } from "react";

export const createAuthHandler = (refreshState: EffectCallback) => {
    const authHandler = ({ payload }: any) => {
        switch (payload.event) {
            case 'configured':
            case 'signIn':
            case 'signIn_failure':
            case 'signOut':
                refreshState();
                break;
            default:
                break;
        }
    };
    return authHandler;
}

/**
 * Auth effect call that handle aws cognito
 * configuration, sign-in and sign-out after
 * finished rendering of the webpage.
 * @param refreshState
 *  Effect callback that handles the auth refresh
 *
 *  @see refreshStateCallback
 */
export const authEffectCallback = (
    refreshState: EffectCallback
) => {
    const result: (() => void) = Hub.listen(
        'auth', createAuthHandler(refreshState));
    return result;
}

/**
 * Callback that sets the interval for the refresh effect call back.
 * Provides a callback to clear the interval.
 * @param refreshState
 *  Effect callback that handles the auth refresh
 */
export const setIntervalRefreshEffectCallback = (
    refreshState: EffectCallback
) => {
    refreshState();
    const interval = window.setInterval(refreshState, 10 * 1000);
    return () => window.clearInterval(interval);
}