import { ConflictCheckCompleteHandler } from "../types/conflict-check-result-types";

/**
 * Submit callback function that handles action
 * after the user clicked on button 'Neue Konfliktprüfung'
 *
 * @param onComplete
 */
export const onSubmitCallback = (onComplete: ConflictCheckCompleteHandler) =>
    () => {
        onComplete({})
    };