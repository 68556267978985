import {
  AuthOptions,
  AwsCognitoOAuthOpts
} from '@aws-amplify/auth/lib-esm/types';
import { Amplify } from 'aws-amplify';


function getAuthOptions(): AuthOptions {
  return {
    region:
      process.env.REACT_APP_COGNITO_REGION as string,
    userPoolId:
      process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
    userPoolWebClientId:
      process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID as string,
    oauth: getOAuthOptions(),
    mandatorySignIn: true,
  };
}

function getOAuthOptions(): AwsCognitoOAuthOpts {
  return {
    domain:
      process.env.REACT_APP_COGNITO_DOMAIN as string,
    scope: ['email', 'openid'],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/bye`,
    responseType: 'code',
  };
}

function warningsForMissingEnvironmentVariables() {
  if (process.env.REACT_APP_BACKEND_ENDPOINT === undefined) {
    console.warn('REACT_APP_BACKEND_ENDPOINT is not set!');
  }
  if (process.env.REACT_APP_COGNITO_USER_POOL_ID === undefined) {
    console.warn('REACT_APP_COGNITO_USER_POOL_ID is not set!');
  }
  if (process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID === undefined) {
    console.warn('REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID is not set!');
  }
}

export const init = async (_callback: Function) => {
  warningsForMissingEnvironmentVariables();

  Amplify.configure(getAuthOptions());
  _callback();
};
