import React from 'react';
import { AccordionVisibility, CompletedState, FilterState } from '../components/upload/ConflictCheckResult';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { stringify } from 'csv-stringify/browser/esm';
import { ImportComparisonDiffed, ImportVariable } from '../types/import-types';
import { BOM } from '../__tests__/utils/constants';
import { createDump } from '../service/dump';


/**
 * Handles reduce of dopix variable entries in accordion.
 * Set conflict resolution state to <code>false</code> (default)
 * @param acc Accumulator
 * @param result Data result
 */
export const accordionEntriesReduceCallback = (
    acc: AccordionVisibility | CompletedState,
    result: ImportComparisonDiffed<ImportVariable>
) => ({
    ...acc,
    [result.dopix.name]: false,
});

export const accordionFilterStateReduceCallback = (
    acc: string [],
    [key, value]: [string, boolean],
) => {
    if (value) {
        return [...acc, key];
    }
    return acc;
};

/**
 * Handles visibility of variables details when button
 * 'Alles ausklappen' or 'Alles einklappen' is clicked.
 * @param variablesDetailsAccordionVisibility
 * @param setVariablesDetailsAccordionVisibility
 * @param areAllVariablesDetailsAccordionsVisible
 */
export const toggleVariablesDetailsAccordionVisibilityCallback = (
    variablesDetailsAccordionVisibility: AccordionVisibility,
    setVariablesDetailsAccordionVisibility: React.Dispatch<React.SetStateAction<AccordionVisibility>>,
    areAllVariablesDetailsAccordionsVisible: boolean
) =>
    () => {
    setVariablesDetailsAccordionVisibility(
        Object.keys(variablesDetailsAccordionVisibility).reduce(
            accordionVariableDetailsReduceCallback(
                areAllVariablesDetailsAccordionsVisible),
            {} as AccordionVisibility,
        ),
    );
}

export const accordionVariableDetailsReduceCallback = (
    areAllVariablesDetailsAccordionsVisible: boolean
) =>
    (
        acc: AccordionVisibility,
        key: string,
    ) => ({
        ...acc,
        [key]: !areAllVariablesDetailsAccordionsVisible,
    });

export function csvExportCallBack(
    filteredVariables: ImportComparisonDiffed<ImportVariable>[] | undefined,
) {
    return () => {
        const dump = createDump(filteredVariables);
        stringify(
            dump, { delimiter: ';' },
            (error: any, records: any) => {
                if (!error && records) {
                    const csvData = `${BOM}${records}`;
                    const blob = new Blob([csvData],
                        { type: 'text/csv;charset=utf-8' });
                    saveAs(blob, `docsm_${format(new Date(),
                        'yyyy-MM-dd_hh-mm-ss')}.csv`,
                    );
                }
            },
        );
    };
}

export const notPresentInDocsAOnClickCallback = (
    variablesAccordionVisibility: AccordionVisibility,
    setVariablesAccordionVisibility: React.Dispatch<React.SetStateAction<AccordionVisibility>>,
) => () =>
    setVariablesAccordionVisibility({
        ...variablesAccordionVisibility,
        notPresentInDocsa: !variablesAccordionVisibility.notPresentInDocsa,
    });

export const presentInDocsAWithNoConflictOnClickCallback = (
    variablesAccordionVisibility: AccordionVisibility,
    setVariablesAccordionVisibility: React.Dispatch<React.SetStateAction<AccordionVisibility>>,
) => () =>
    setVariablesAccordionVisibility({
        ...variablesAccordionVisibility,
        presentInDocsaWithNoConflict: !variablesAccordionVisibility.presentInDocsaWithNoConflict,
    });

export const presentInDocsAWithConflictOnClickCallback = (
    variablesAccordionVisibility: AccordionVisibility,
    setVariablesAccordionVisibility: React.Dispatch<React.SetStateAction<AccordionVisibility>>,
) => () =>
    setVariablesAccordionVisibility({
        ...variablesAccordionVisibility,
        presentInDocsaWithConflict: !variablesAccordionVisibility.presentInDocsaWithConflict,
    })

export const autoConflictsHandledOnClickCallback = (
    variablesAccordionVisibility: AccordionVisibility,
    setVariablesAccordionVisibility: React.Dispatch<React.SetStateAction<AccordionVisibility>>,
) => () =>
    setVariablesAccordionVisibility({
        ...variablesAccordionVisibility,
        autoConflictsHandled: !variablesAccordionVisibility.autoConflictsHandled,
    });

export const setFilterStateOnClickCallBack = (
    variablesFilterState: FilterState<ImportVariable> | any,
    setVariablesFilterState: React.Dispatch<React.SetStateAction<FilterState<ImportVariable>>>,
    key: string,
) => () =>
    setVariablesFilterState({
        ...variablesFilterState,
        [key]: !variablesFilterState[key],
    });

/**
 * Filters accordion entries based on active filters
 * and reverse diffs state, when radio button is clicked.
 * @param activeFilters
 */
export const accordionEntryFilter = (activeFilters: string[]) =>
    (result: ImportComparisonDiffed<ImportVariable>) => {
        return activeFilters.every(
            (activeFilter) => !result.diffs[activeFilter],
        );
    };