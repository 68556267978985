import React from 'react';

import './Footer.scss';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
          <div className="footer__copyright">© fcb solutions GmbH</div>
          <div className="footer__meta">
            Aktuelles Release: {process.env.REACT_APP_RELEASE || 'latest'}
          </div>
    </footer>
  );
};
