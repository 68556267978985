export type Diff = {
  [id: string]: boolean;
};

export type ImportVariable = {
  name: string;
  type: string;
  pattern: string;
  defaultValue: string;
  dopixPath: string;
  requiredValue: boolean;
};

export type ImportComparison<T> = {
  dopix: T;
  docsa: T;
};

export type ImportComparisonDiffed<T> = ImportComparison<T> & {
  diffs: Diff;
};

export type ImportCheckResult<T> = {
  notPresentInDocsa?: T[];
  presentInDocsaWithNoConflict?: T[];
  presentInDocsaWithConflict?: ImportComparison<T>[];
  autoConflictsHandled?: T[];
  result?: T;
};

export type ImportCheckResultDiffed<T> = {
  notPresentInDocsa?: T[];
  presentInDocsaWithNoConflict?: T[];
  presentInDocsaWithConflict?: ImportComparisonDiffed<T>[];
  autoConflictsHandled?: T[];
  result?: T;
};

export type ImportResult = ImportCheckResult<ImportVariable>;

export type ImportStatsResult = {
  numberOfDocumentsImported: number;
  numberOfVariablesImported: number;
  numberOfValueListsImported: number;
  docsaImportResultSingleDocumentList: {
    numberOfSectionsInDocument: number;
    numberOfTextblocksInDocument: number;
    numberOfNewSectionsImported: number;
    numberOfNewTextblocksImported: number;
    documentName: string;
  }[];
};

export class ImportError implements Error {
  constructor(
      public name: string,
      public message: string = '',
      public timestamp = '',
      public data = {},
  ) {}

  public static isAssignable(error: any) {
    return error &&
        error.constructor === Object &&
        'timestamp' in error &&
        'message' in  error &&
        'data' in error;
  }

}
