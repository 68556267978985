import React from 'react';
import { FileResult, isDopixFileResult } from '../utils/dopix';

export type DopixDocumentInformationProps = {
  documentType: FileResult;
};

export const DopixDocumentInformation: React.FC<DopixDocumentInformationProps> = ({
  documentType,
}) => {
  return isDopixFileResult(documentType) ? (
    <>
      <p>
        <strong>{documentType.fileName}</strong>
      </p>
      {documentType.releaseId ||
      documentType.buildId ||
      documentType.fixLevel ||
      documentType.releaseDate ? (
        <>
          {documentType.releaseId &&
          documentType.buildId &&
          documentType.fixLevel ? (
            <div>
              DOPiX {documentType.releaseId}.{documentType.buildId}.
              {documentType.fixLevel}
            </div>
          ) : (
            ''
          )}
          {documentType.releaseDate ? (
            <div>Release-Datum: {documentType.releaseDate}</div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      {documentType.dsfVersion ? (
        <div>DSF-Version: {documentType.dsfVersion}</div>
      ) : (
        ''
      )}
      {documentType.timestamp ? (
        <div>Entladezeitpunkt: {documentType.timestamp}</div>
      ) : (
        ''
      )}
    </>
  ) : null;
};
