import React, { useState } from "react";
import { AbstractStep, UploadData, uploadStep } from "../types/upload-types";
import { AbstractPage } from "./AbstractPage";
import { conflictCheckResultStep } from "../types/conflict-check-result-types";
import { onSubmitConflictCheck } from "../view/conflict-check-upload-button";

export const ConflictCheck: React.FC = () => {
  const dataUseState = useState<UploadData>({});
  const stepUseState = useState<AbstractStep>(uploadStep);
  uploadStep.nextStep = conflictCheckResultStep;
  conflictCheckResultStep.nextStep = uploadStep;

  return new AbstractPage(
      dataUseState,
      stepUseState,
    "Hier können Sie Dateien auf Konflikte überprüfen",
      onSubmitConflictCheck,
    ).getComponent();
}
