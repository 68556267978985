import React, { FC, useContext } from 'react';
import { Navigate, Outlet, PathRouteProps, Route } from 'react-router-dom';
import { AuthContext } from './context';

export type ProtectedRouteProps = PathRouteProps & {
    authorized: boolean;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  authorized
}) => {
  const auth = useContext(AuthContext);
  if (authorized) {
      return <Outlet />
  } else {
      auth?.signIn();
      return <Navigate replace to={"/"}/>
  }
};

interface RouteProperties {
    path: string,
    element: React.ReactNode;
}

interface ProtectedRouteProperties {
    path: string,
    element: React.ReactNode,
    authorized: boolean;
}

/**
 * Methods that creates a {@link Route}
 * @param properties
 *          Needed {@link RouteProperties} to create a {@link Route}
 */
function buildRoute(
    properties: RouteProperties
){
    return <Route path={properties.path} element={properties.element} />
}

/**
 * Methods that creates a {@link ProtectedRoute}
 * @param properties
 *          Needed {@link ProtectedRouteProperties} to create a {@link ProtectedRoute}
 */
function buildProtectedRoute(
    properties: ProtectedRouteProperties
) {
    return <Route path={properties.path}
                  element={<ProtectedRoute authorized={properties.authorized}/>} >
             <Route path={properties.path} element={properties.element}/>
           </Route>
}

/**
 * Represents a class that builds {@link Route} by setting
 * {@link RouteBuilder#path()} and {@link RouteBuilder#element()}.
 */
class RouteBuilder {
    protected pathProp: string | undefined = undefined
    protected elementProp: React.ReactNode | undefined = undefined
    public path(path: string) {
        this.pathProp = path;
        return this;
    }
    public element(element: React.ReactNode) {
        this.elementProp = element;
        return this;
    }
    public build() {
        return buildRoute({
            path: this.pathProp!,
            element: this.elementProp!,
        })
    }
}

/**
 * Represents a class that builds (JSX.Element) ProtectedRoutes by setting
 * path("..."), element(<...>) and authorized(true | false).
 */
class ProtectedRouteBuilder extends RouteBuilder {
    private authorizedProp: boolean | undefined = undefined
    public authorized(authorized: boolean) {
        this.authorizedProp = authorized;
        return this;
    }
    public build() {
        return buildProtectedRoute({
            path: this.pathProp!,
            element: this.elementProp!,
            authorized: this.authorizedProp!
        })
    }
}

/**
 * Represents a factory class that provides a {@link RouteBuilder}.
 */
export class RouteFactory {
    public static builder() {
        return new RouteBuilder()
    }
}

/**
 * Represents a factory class that provides a {@link ProtectedRouteBuilder}.
 */
export class ProtectedRouteFactory {
    public static builder() {
        return new ProtectedRouteBuilder()
    }
}