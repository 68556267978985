import { createStore } from 'redux';
import {
  SET_DOCUMENT_TYPE,
  SET_STATS_TYPE,
  SystemActionTypes,
} from './actions';
import { SystemState } from './types';

const initialState: SystemState = {};

export function systemReducer(
  state = initialState,
  action: SystemActionTypes,
): SystemState {
  switch (action.type) {
    case SET_DOCUMENT_TYPE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_STATS_TYPE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}

export const store = createStore(systemReducer);
