import React, { SyntheticEvent } from "react";
import { DropdownProps } from "semantic-ui-react";
import { CcmSystemSettings, UploadFormData } from "../types/upload-types";

/**
 * Callback function that updates form data when tenant is
 * changed in dropdown.
 * @param setFormData callback to set form data
 * @param formData form data for upload.
 */
export const onCcmSystemChange = (
    setCcmSystem: (value: React.SetStateAction<CcmSystemSettings>) => void,
    setFormData: (value: React.SetStateAction<UploadFormData>) => void,
    formData: UploadFormData,
) => (
    _: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
) => {
    if (data.value) {
        // the data options contain all possible CcmSystemSettings
        const selectedCcmSystem = data.options?.filter(o => o.value === data.value)[0] as CcmSystemSettings
        setCcmSystem(selectedCcmSystem);

        const acceptProperty = selectedCcmSystem.accept
        document.getElementById("fileUploadInput")?.setAttribute("accept", acceptProperty);

        setFormData({
            ...formData,
            ccmSystem: String(data.value),
        });
    }
};
