import { SystemState } from './types';

export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const CLEAR_DOCUMENT_TYPE = 'CLEAR_DOCUMENT_TYPE';
export const SET_STATS_TYPE = 'SET_STATS_TYPE';
export const CLEAR_STATS_TYPE = 'CLEAR_STATS_TYPE';

export type SetDocumentTypeAction = {
  type: typeof SET_DOCUMENT_TYPE;
  payload: SystemState;
};

export type ClearDocumentTypeAction = {
  type: typeof CLEAR_DOCUMENT_TYPE;
};

export type SetStatsTypeAction = {
  type: typeof SET_STATS_TYPE;
  payload: SystemState;
};

export type ClearStatsTypeAction = {
  type: typeof CLEAR_STATS_TYPE;
};

export type SystemActionTypes =
  | SetDocumentTypeAction
  | ClearDocumentTypeAction
  | SetStatsTypeAction
  | ClearStatsTypeAction;
