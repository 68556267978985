import React, { useCallback, useRef, useState } from "react";
import {
    Form,
    Icon,
    Table,
    Button,
    Checkbox,
    Accordion
} from "semantic-ui-react";
import {
    autoConflictsHandledTableRowMapper,
    conflictCheckResolvedFilterRowMapper,
    notPresentInDocsATableRowMapper,
    presentInDocsAWithNoConflictTableRowMapper,
} from '../../view/import-variable-table-row-mapper';
import {
    csvExportCallBack,
    accordionEntryFilter,
    setFilterStateOnClickCallBack,
    accordionEntriesReduceCallback,
    notPresentInDocsAOnClickCallback,
    accordionFilterStateReduceCallback,
    autoConflictsHandledOnClickCallback,
    presentInDocsAWithConflictOnClickCallback,
    presentInDocsAWithNoConflictOnClickCallback,
    toggleVariablesDetailsAccordionVisibilityCallback,
} from '../../callback/accordion-callback';
import { ImportVariable } from '../../types/import-types';
import { onSubmitCallback } from '../../callback/submit-callback';
import { ConflictCheckFormStep } from "../../types/conflict-check-result-types";

import './ConflictCheckResult.scss'


export type AccordionVisibility = {
    [id: string]: boolean;
};

export type CompletedState = {
    [id: string]: boolean;
};

export type FilterState<T extends {}> = {
    [K in keyof T]: boolean;
};

export const LABELS = {
    variables: {
        name: 'Name',
        type: 'Typ',
        pattern: 'Pattern',
        defaultValue: 'Default-Wert',
        dopixPath: 'Dopix-Pfad',
        requiredValue: 'Erforderlich?',
    },
} as const;

export const ConflictCheckResult: React.FC<ConflictCheckFormStep> = ({data, onComplete}) => {
    const copyRef = useRef<HTMLDivElement>(null);
    const [
        variablesAccordionVisibility,
        setVariablesAccordionVisibility,
    ] = useState<AccordionVisibility>({
        notPresentInDocsa: false,
        presentInDocsaWithNoConflict: false,
        presentInDocsaWithConflict: true,
        autoConflictsHandled: false,
    });
    const [
        variablesDetailsAccordionVisibility,
        setVariablesDetailsAccordionVisibility,
    ] = useState<AccordionVisibility>(
        data.conflictCheckImportResult?.presentInDocsaWithConflict!.reduce(
            accordionEntriesReduceCallback,
            {} as AccordionVisibility,
        ) || {},
    );
    const [
        variablesDetailsCompletedState,
        setVariablesDetailsCompletedState,
    ] = useState<CompletedState>(
        data.conflictCheckImportResult?.presentInDocsaWithConflict!.reduce(
            accordionEntriesReduceCallback,
            {} as CompletedState,
        ) || {},
    );

    const [variablesFilterState, setVariablesFilterState] = useState<FilterState<ImportVariable>>({
        name: false,
        type: false,
        pattern: false,
        defaultValue: false,
        dopixPath: false,
        requiredValue: false,
    });

    const activeFilters = Object.entries(variablesFilterState).reduce(
        accordionFilterStateReduceCallback, [] as string[]);

    const filteredVariables = (activeFilters.length > 0)
        ? data.conflictCheckImportResult?.presentInDocsaWithConflict!
                .filter(accordionEntryFilter(activeFilters))
        : data.conflictCheckImportResult?.presentInDocsaWithConflict;

    const areAllVariablesDetailsAccordionsVisible = Object.values(
        variablesDetailsAccordionVisibility).every(Boolean);

    // eslint-disable-next-line
    const toggleVariablesDetailsAccordionVisibility = useCallback(
        toggleVariablesDetailsAccordionVisibilityCallback(
            variablesDetailsAccordionVisibility,
            setVariablesDetailsAccordionVisibility,
            areAllVariablesDetailsAccordionsVisible,
        ), [
            variablesDetailsAccordionVisibility,
            areAllVariablesDetailsAccordionsVisible,
        ]);

    // eslint-disable-next-line
    const exportAsCsv = useCallback(csvExportCallBack(filteredVariables),
        [filteredVariables]);

    return (
        <div className="conflict-check__review">
            <Form>
                <div className="conflict-check__section">Variablen</div>
                <Accordion
                    className="conflict-check__accordion"
                    exclusive={false}
                    fluid
                >
                    {/* Not present in docsA⁺ */}
                    <Accordion.Title
                        active={variablesAccordionVisibility.notPresentInDocsa}
                        onClick={notPresentInDocsAOnClickCallback(
                            variablesAccordionVisibility,
                            setVariablesAccordionVisibility
                        )}>
                        <Icon
                            name={
                                variablesAccordionVisibility.notPresentInDocsa
                                    ? 'angle down'
                                    : 'angle right'
                            }
                        />{' '}
                        Nicht vorhanden in docsA⁺ (
                        {data.conflictCheckImportResult?.notPresentInDocsa!.length})
                    </Accordion.Title>
                    <Accordion.Content
                        active={variablesAccordionVisibility.notPresentInDocsa}
                    >
                        {data.conflictCheckImportResult?.notPresentInDocsa!.length ? (
                            <Table celled striped className="conflict-check__table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{LABELS.variables.name}</Table.HeaderCell>
                                        <Table.HeaderCell>{LABELS.variables.type}</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.pattern}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.defaultValue}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.dopixPath}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.requiredValue}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data.conflictCheckImportResult?.notPresentInDocsa.map(
                                        notPresentInDocsATableRowMapper)}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                    </Accordion.Content>
                    {/* Present in docsA⁺ with no conflict */}
                    <Accordion.Title
                        active={variablesAccordionVisibility.presentInDocsaWithNoConflict}
                        onClick={presentInDocsAWithNoConflictOnClickCallback(
                            variablesAccordionVisibility,
                            setVariablesAccordionVisibility
                        )}>
                        <Icon
                            name={
                                variablesAccordionVisibility.presentInDocsaWithNoConflict
                                    ? 'angle down'
                                    : 'angle right'
                            }
                        />{' '}
                        Vorhanden in docsA⁺ ohne Konflikte (
                        {data.conflictCheckImportResult?.presentInDocsaWithNoConflict!.length})
                    </Accordion.Title>
                    <Accordion.Content
                        active={variablesAccordionVisibility.presentInDocsaWithNoConflict}
                    >
                        {data.conflictCheckImportResult?.presentInDocsaWithNoConflict!.length ? (
                            <Table celled striped className="conflict-check__table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{LABELS.variables.name}</Table.HeaderCell>
                                        <Table.HeaderCell>{LABELS.variables.type}</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.pattern}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.defaultValue}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.dopixPath}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.requiredValue}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data.conflictCheckImportResult?.presentInDocsaWithNoConflict.map(
                                        presentInDocsAWithNoConflictTableRowMapper)}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                    </Accordion.Content>
                    {/* Present in docsA⁺ with conflict */}
                    <Accordion.Title
                        active={variablesAccordionVisibility.presentInDocsaWithConflict}
                        onClick={presentInDocsAWithConflictOnClickCallback(
                            variablesAccordionVisibility,
                            setVariablesAccordionVisibility
                        )}>
                        <Icon
                            name={
                                variablesAccordionVisibility.presentInDocsaWithConflict
                                    ? 'angle down'
                                    : 'angle right'
                            }
                        />{' '}
                        Vorhanden in docsA⁺ mit Konflikten (
                        {activeFilters.length > 0
                            ? `${filteredVariables?.length}/${data.conflictCheckImportResult?.presentInDocsaWithConflict!.length}`
                            : data.conflictCheckImportResult?.presentInDocsaWithConflict!.length}
                        )
                    </Accordion.Title>
                    <Accordion.Content
                        active={variablesAccordionVisibility.presentInDocsaWithConflict}
                    >
                        <div className="conflict-check__bar">
                            <div className="conflict-check__filter">
                                <div className="conflict-check__filter-label">
                                    Konflikte ignorieren:
                                </div>
                                <div className="conflict-check__filter-items">
                                    {([
                                        'name',
                                        'type',
                                        'pattern',
                                        'defaultValue',
                                        'dopixPath',
                                        'requiredValue',
                                    ] as const).map((key) => (
                                        /*
                                         * aria-labelledby creates an association
                                         * between label (by id) and checkbox so that checkbox
                                         * can be found via label text in unit test.
                                         * @see https://testing-library.com/docs/queries/bylabeltext/
                                         */
                                        <Checkbox
                                            aria-labelledby={`label__${key}`}
                                            key={`filter_variables_${key}`}
                                            onChange={setFilterStateOnClickCallBack(
                                                variablesFilterState,
                                                setVariablesFilterState,
                                                key
                                            )}
                                            label={<label id={`label__${key}`}>{LABELS.variables[key]}</label>}
                                            className="conflict-check__filter-item"
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className="conflict-check__actions">
                                <div className="conflict-check__action">
                                    <Button
                                        icon
                                        labelPosition="left"
                                        size="mini"
                                        onClick={toggleVariablesDetailsAccordionVisibility}
                                    >
                                        <Icon
                                            name={
                                                areAllVariablesDetailsAccordionsVisible
                                                    ? 'minus'
                                                    : 'plus'
                                            }
                                        />
                                        Alle{' '}
                                        {areAllVariablesDetailsAccordionsVisible
                                            ? 'einklappen'
                                            : 'ausklappen'}
                                    </Button>
                                </div>
                                <div className="conflict-check__action">
                                    <Button
                                        icon
                                        labelPosition="left"
                                        size="mini"
                                        onClick={exportAsCsv}
                                    >
                                        <Icon name="download"/>
                                        CSV
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {filteredVariables?.length ? (
                            <div ref={copyRef}>
                                <Accordion
                                    className="conflict-check__accordion"
                                    exclusive={false}
                                    fluid
                                    styled
                                >
                                    {filteredVariables.map(
                                        conflictCheckResolvedFilterRowMapper(
                                            variablesDetailsAccordionVisibility,
                                            setVariablesDetailsAccordionVisibility,
                                            variablesDetailsCompletedState,
                                            setVariablesDetailsCompletedState
                                        )
                                    )}
                                </Accordion>
                            </div>
                        ) : (
                            ''
                        )}
                    </Accordion.Content>
                    {/* Conflicts automatically handled */}
                    <Accordion.Title
                        active={variablesAccordionVisibility.autoConflictsHandled}
                        onClick={autoConflictsHandledOnClickCallback(
                            variablesAccordionVisibility,
                            setVariablesAccordionVisibility
                        )}>
                        <Icon
                            name={
                                variablesAccordionVisibility.autoConflictsHandled
                                    ? 'angle down'
                                    : 'angle right'
                            }
                        />{' '}
                        Automatisch behobene Konflikte (
                        {data.conflictCheckImportResult?.autoConflictsHandled!.length})
                    </Accordion.Title>
                    <Accordion.Content
                        active={variablesAccordionVisibility.autoConflictsHandled}
                    >
                        {data.conflictCheckImportResult?.autoConflictsHandled!.length ? (
                            <Table celled striped className="conflict-check__table">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{LABELS.variables.name}</Table.HeaderCell>
                                        <Table.HeaderCell>{LABELS.variables.type}</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.pattern}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.defaultValue}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.dopixPath}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {LABELS.variables.requiredValue}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {data.conflictCheckImportResult?.autoConflictsHandled!.map(
                                        autoConflictsHandledTableRowMapper)}
                                </Table.Body>
                            </Table>
                        ) : (
                            ''
                        )}
                    </Accordion.Content>
                </Accordion>

                <Button type="submit" onClick={onSubmitCallback(onComplete)} primary>
                    Neue Konfliktprüfung
                </Button>
            </Form>
        </div>
    );
};