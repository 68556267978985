import React from 'react';
import { CognitoUser } from '../service/auth';

export interface AuthContextProperties {
  isAuthenticated: boolean;
  user?: CognitoUser;
  signIn: () => void;
  signOut: () => void;
}

export const AuthContext =
    React.createContext<AuthContextProperties | null>(null);
