import { Auth } from 'aws-amplify';


export const getAccessToken = async (): Promise<string> => {
  const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
  return user.signInUserSession.accessToken.jwtToken;
};

export const getAuthenticationState = (user: any): boolean => {
  if (
    !user ||
    !user.signInUserSession ||
    !user.signInUserSession.isValid ||
    !user.signInUserSession.accessToken ||
    !user.signInUserSession.accessToken.getExpiration
  ) {
    return false;
  }

  const session = user.signInUserSession;
  const isValid = session.isValid() || false;

  const sessionExpiry = new Date(session.accessToken.getExpiration() * 1000);
  const isExpired = new Date() > sessionExpiry;

  return isValid && !isExpired;
};

export type CognitoUser = {
  username: string;
  pool: {
    userPoolId: string;
    clientId: string;
    client: {
      endpoint: string;
    };
    advancedSecurityDataCollectionFlag: boolean;
  };
  client: {
    endpoint: string;
  };
  signInUserSession: {
    idToken: {
      jwtToken: string;
    };
    refreshToken: {
      token: string;
    };
    accessToken: {
      jwtToken: string;
    };
  };
  authenticationFlowType: string;
  keyPrefix: string;
  userDataKey: string;
  storage: {
    [key: string]: unknown | undefined;
  };
};
