import React, { useState } from "react";
import { AbstractStep, UploadData, uploadStep } from "../types/upload-types";
import { homeResultStep } from "../types/home-result-types";
import { AbstractPage } from "./AbstractPage";
import { onSubmitHome } from "../view/file-upload-button";


export const Home: React.FC = () => {
  const dataUseState = useState<UploadData>({});
  const stepUseState = useState<AbstractStep>(uploadStep);
  uploadStep.nextStep = homeResultStep;
  homeResultStep.nextStep = uploadStep;

  return new AbstractPage(
        dataUseState,
        stepUseState,
        "Hier können Sie Dateien importieren",
        onSubmitHome,
        true,
      ).getComponent();
};
