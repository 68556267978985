import React from "react";
import { AbstractStep, SubmitProperties, UploadData } from "../types/upload-types";
import { Message } from "semantic-ui-react";

export const getOnCompleteFunction = (
    dataUseState: [UploadData, React.Dispatch<React.SetStateAction<UploadData>>],
    stepUseState: [AbstractStep, React.Dispatch<React.SetStateAction<AbstractStep>>]
) => {
  return (newData: UploadData) => {
    const [data, setData] = dataUseState;
    const [step, setStep] = stepUseState;
    setData({
      ...data,
      ...newData,
    });
    setStep(step.nextStep!);
  };
}

export class AbstractPage {
  constructor(
      public dataUseState: [UploadData, React.Dispatch<React.SetStateAction<UploadData>>],
      public stepUseState: [AbstractStep, React.Dispatch<React.SetStateAction<AbstractStep>>],
      public infoMessage: string,
      public submitButtonCallback: (submitProperties: SubmitProperties) => void,
      public showOverwriteToggle = false,
  ) {}

  public getComponent(): JSX.Element {
    const [data] = this.dataUseState;
    const [step] = this.stepUseState;
    return (
        <div className="home">
          <Message role='home-message-box-info' info visible>
            <p>{this.infoMessage}</p>
          </Message>
          {
            step.render(
              this.submitButtonCallback,
              this.showOverwriteToggle,
              data,
              {
                onComplete: getOnCompleteFunction(this.dataUseState, this.stepUseState),
                submitCallback: this.submitButtonCallback,
                showOverwriteToggle: this.showOverwriteToggle
              })
          }
        </div>
    );
  }
}
