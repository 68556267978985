import { ImportComparisonDiffed, ImportVariable } from '../types/import-types';
import { LABELS } from '../components/upload/ConflictCheckResult';
import { EMPTY_LINE, NO, YES } from '../__tests__/utils/constants';

export function createDump(
    filteredVariables: ImportComparisonDiffed<ImportVariable>[] | undefined,
) {
    const dump = [
        [
            'System',
            LABELS.variables.name,
            LABELS.variables.type,
            LABELS.variables.pattern,
            LABELS.variables.defaultValue,
            LABELS.variables.dopixPath,
            LABELS.variables.requiredValue,
        ],
    ];
    (filteredVariables || []).forEach((result) => {
        dump.push(EMPTY_LINE);
        dump.push([
            'Dopix',
            result.dopix.name,
            result.dopix.type,
            result.dopix.pattern,
            result.dopix.defaultValue,
            result.dopix.dopixPath,
            result.dopix.requiredValue ? YES : NO,
        ]);
        dump.push([
            'docsA⁺',
            result.docsa.name,
            result.docsa.type,
            result.docsa.pattern,
            result.docsa.defaultValue,
            result.docsa.dopixPath,
            result.docsa.requiredValue ? YES : NO,
        ]);
    });
    return dump;
}