import React from "react";
import { useSelector } from "react-redux";
import { SystemState } from "../../store/types";
import { FileResult } from "../../utils/dopix";
import { ImportStatsResult } from "../../types/import-types";
import { Button, Form, Message } from "semantic-ui-react";
import { DopixDocumentInformation } from "../DopixDocumentInformation";
import { UploadFormStep } from "../../types/upload-types";

export const Result: React.FC<UploadFormStep> = (
    { onComplete }: UploadFormStep
) => {
    const documentType = useSelector<SystemState, FileResult>(
        (state: SystemState) => state.documentType,
    );
    const stats = useSelector<SystemState, ImportStatsResult | undefined>(
        (state: SystemState) => state.stats,
    );

    const onSubmit = () => {
        onComplete({});
    };

    return (
        <div className="home__result">
            <Form>
                <Message success visible>
                    <p>Der Import wurde erfolgreich abgeschlossen.</p>
                    {documentType ? (
                        <DopixDocumentInformation documentType={documentType}/>
                    ) : null}
                    {stats ? (
                        <>
                            <p>
                                Anzahl der importierten Dokumente:{' '}
                                {stats.numberOfDocumentsImported}
                                <br/>
                                Anzahl der importierten Variablen:{' '}
                                {stats.numberOfVariablesImported}
                                <br/>
                                Anzahl der importierten Wertelisten:{' '}
                                {stats.numberOfValueListsImported}
                            </p>
                            {stats.docsaImportResultSingleDocumentList.length ? (
                                <>
                                    <p>
                                        <strong>Auswertungen pro Dokument</strong>
                                    </p>
                                    {stats.docsaImportResultSingleDocumentList.map((item: any) => (
                                        <>
                                            <p>
                                                <strong>{item.documentName}</strong>
                                            </p>
                                            <p>
                                                Anzahl an Kapiteln (Sections) im Dokument:{' '}
                                                {item.numberOfSectionsInDocument}
                                                <br/>
                                                Anzahl an neu importierten Kapiteln (Sections, ohne
                                                Präfix): {item.numberOfNewSectionsImported}
                                                <br/>
                                                Anzahl an Textbausteinen (Textblocks) im Dokument:{' '}
                                                {item.numberOfTextblocksInDocument}
                                                <br/>
                                                Anzahl an neu importierten Textbausteinen (Textblocks,
                                                ohne Präfix): {item.numberOfNewTextblocksImported}
                                            </p>
                                        </>
                                    ))}
                                </>
                            ) : null}
                        </>
                    ) : null}
                </Message>
                <Button type="submit" onClick={onSubmit} primary>
                    Neuer Import
                </Button>
            </Form>
        </div>
    );
};