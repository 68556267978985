import React from 'react';
import { Message } from 'semantic-ui-react';

export const Bye: React.FC = () => {
  return (
    <div className="bye">
      <Message info visible>
        <p>Sie wurden abgemeldet.</p>
      </Message>
    </div>
  );
};
