import React, { EffectCallback } from 'react';
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types/Auth";
import { CognitoUser, getAuthenticationState } from "../service/auth";
import { AuthContextProperties } from '../utils';

type OptionalCognitoUser = CognitoUser | undefined;

/**
 * Callback function that handles the user sign-in
 * after the user pressed the login button.
 */
export const userSignInCallback = (auth?: AuthContextProperties | null) =>
    () => auth?.signIn();

/**
 * Callback function that handles the user sign-out
 * after the user pressed the logout button.
 */
export const userSignOutCallBack = (auth: AuthContextProperties | null) =>
    () => auth?.signOut();

/**
 * Callback function that handles the federated cognito sign-in
 * after the user authorise himself with username and password.
 * A CognitoHostedUIIdentityProvider is used to handle the sign-in.
 */
export const signInCallBack = () => {
    // noinspection JSIgnoredPromiseFromCall
    return Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito,
    });
}


export interface RefreshStateProps {
    setUser: React.Dispatch<React.SetStateAction<OptionalCognitoUser>>,
    setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
    setReady: React.Dispatch<React.SetStateAction<boolean>>
}
/**
 * Callback function that handles the refresh state of
 * already authorized user after browser refresh.
 * This enables that the user don't need to re-authorize
 * When a browser was closed or website was refreshed.
 */
/*
 * It is really hard to test an inline function so an exported
 * callback function which can be tested in isolation!
 */
export const performRefreshStateCallback = (refreshStateProps: RefreshStateProps) =>
    () => performRefreshState(refreshStateProps);
export const performRefreshState = (
    {
        setUser,
        setIsAuthenticated,
        setReady
    }: RefreshStateProps
) => {
    Auth.currentAuthenticatedUser()
        .then((user) => {
            setUser(user);
            setIsAuthenticated(getAuthenticationState(user));
            setReady(true);
        })
        .catch(() => {
            setUser(undefined);
            setIsAuthenticated(false);
            setReady(true);
        });
}

/**
 * Callback function that handles the cognito sign-out
 * after the user pressed the logout button.
 */
/*
 * It is really hard to test an inline function so an exported
 * callback function which can be tested in isolation!
 */
export const cognitoSignOutCallback = (refreshState: EffectCallback) =>
    () => cognitoSignOut(refreshState);
export const cognitoSignOut = (
    refreshState: EffectCallback
) => {
    Auth.signOut().then(() => {
        refreshState();
    });
}