import React, { SyntheticEvent } from 'react';
import { DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { extractError, getInstances, Instance } from "../service/api";
import { ImportError } from "../types/import-types";
import { UploadFormData } from "../types/upload-types";


/**
 * Set up the dropdown to select available fcbsuite
 * instances.
 * @param rawInstances raw available instances.
 * @param formData form data for file upload.
 */
export const setupInstanceDropdown = (
    rawInstances: Instance[],
    formData: UploadFormData
): DropdownItemProps[] => {
    const currentInstance = rawInstances.find(
        (rawInstance) => rawInstance.title === formData.instance,
    );

    if (!currentInstance) {
        return [];
    }

    return currentInstance.tenants.map((tenant) => ({
        key: tenant.title,
        text: tenant.title,
        value: tenant.title,
    }));
}

/**
 * Fetch the available instance and add them
 * to the dropdown.
 * @param setRawInstances callback to set raw available instances
 * @param setInstances callback to set available view mapped instances
 * @param setIsLoadingInstances callback to set status of loaded instances
 * @param setError  callback to set error during failed instances loading
 */
export const fetchInstances = async (
    setRawInstances: (value: React.SetStateAction<Instance[]>) => void,
    setInstances: (value: React.SetStateAction<DropdownItemProps[]>) => void,
    setIsLoadingInstances: (value: React.SetStateAction<boolean>) => void,
    setError: (value: React.SetStateAction<ImportError | null>) => void
) => {
    try {
        const results = await getInstances();
        setRawInstances(results);
        setInstances(
            results.map((instance) => ({
                key: instance.title,
                text: instance.title,
                value: instance.title,
            })),
        );
        setIsLoadingInstances(false);
    } catch (e: any) {
        const error = extractError(e);
        if (error instanceof ImportError) {
            setError(error);
        } else {
            setError({
                name: 'Instance fetch error',
                timestamp: '',
                message: 'Es konnte keine Verbindung zu docsA\u207A hergestellt werden.',
                data: {},
            } as ImportError);
        }
    }
};

/**
 * Callback function that updates form data when instance is
 * changed in dropdown.
 * @param setFormData callback to set form data
 * @param formData form data for upload.
 */
export const onInstanceChange = (
    setFormData: (value: React.SetStateAction<UploadFormData>) => void,
    formData: UploadFormData,
) => (
    _: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
) => {
    if (data.value) {
        setFormData({
            ...formData,
            instance: String(data.value),
        });
    }
};