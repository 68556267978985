import axios from "axios";
import { getAccessToken } from "./auth";
import { ImportError } from "../types/import-types";

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

type RequestHeaders = {
  [key: string]: string;
};

export type RequestOptions = {
  body?: BodyInit;
  unauthenticated?: boolean;
  headers?: RequestHeaders;
  onProgress?: (completed: number) => void;
};

export const fetch = async <T>(
  method: RequestMethod,
  url: string,
  options?: RequestOptions,
): Promise<T> => {
  const headers: RequestHeaders = {};
  if (!options?.unauthenticated) {
    const token = await getAccessToken();
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const { data } = await axios({
      method,
      headers,
      url: `${process.env.REACT_APP_BACKEND_ENDPOINT}${url}`,
      timeoutErrorMessage: `Die '${method}' Anfrage zur url: ` +
      `'${process.env.REACT_APP_BACKEND_ENDPOINT}${url}' ` +
      'hat zu lange benötigt, daher kam es zu einem Time-Out',
      data: options?.body,
      onUploadProgress: options?.onProgress
        ? (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total!,
            );
            options?.onProgress?.(percentCompleted);
          }
        : undefined,
    });
    return data;
  } catch (error: any) {
    if (error.response === undefined) {
      throw new Error(
          error
      );
    }
    throw new FetchError(
        "FetchError",
        error.response.data.message,
        error.response.data.timestamp,
        error.response.data.data);
  }
};

/**
 * This error indicates that something went wrong during fetching
 */
export class FetchError extends ImportError {}

export const plain = async (
  method: RequestMethod,
  url: string,
  options?: RequestOptions,
): Promise<string> => {
  return fetch<string>(method, url, options);
};

export const json = async <T>(
  method: RequestMethod,
  url: string,
  options?: RequestOptions,
): Promise<T> => {
  return fetch<T>(method, url, options);
};

export const upload = async <T>(
  url: string,
  options: RequestOptions & { formData: FormData; },
): Promise<T> => {
  return fetch<T>('POST', url, {
    ...options,
    body: options.formData,
  });
};

export const get = async <T>(
  url: string,
  options?: RequestOptions,
): Promise<T> => {
  return json('GET', url, options);
};

export const post = async <T>(
  url: string,
  options?: RequestOptions,
): Promise<T> => {
  return json('POST', url, options);
};

export const put = async <T>(
  url: string,
  options?: RequestOptions,
): Promise<T> => {
  return json('PUT', url, options);
};

export const del = async <T>(
  url: string,
  options?: RequestOptions,
): Promise<T> => {
  return json('DELETE', url, options);
};
