import React, { useContext } from 'react';
import { AuthContext } from '../utils';
import { NavLink } from 'react-router-dom';
import { HeaderNavigationLink } from './HeaderNavigationLink';

import './Header.scss';
import { pageCheckedReload } from '../view/reload';
import { userSignInCallback, userSignOutCallBack } from '../callback/auth-callback';



export const Header: React.FC = () => {
  const auth = useContext(AuthContext);
  return (
    <header className='header'>
      <NavLink to='/' className='header__logo' onClick={pageCheckedReload('/')}>
        docsM⁺
      </NavLink>
      <div className='header__content'>
        <nav className='header__navigation'>
          <ul className='header__navigation-items'>
            {auth?.isAuthenticated ? (
              <>
                <li className='header__navigation-item'>
                  <HeaderNavigationLink to='/'>Import</HeaderNavigationLink>
                </li>
                <li className='header__navigation-item'>
                  <HeaderNavigationLink to='/conflict-check'>
                    Konfliktprüfung
                  </HeaderNavigationLink>
                </li>
                <li className='header__navigation-item'>
                  <HeaderNavigationLink onClick={userSignOutCallBack(auth)}>
                    Logout
                  </HeaderNavigationLink>
                </li>
              </>
            ) : (
              <>
                <li className='header__navigation-item'>
                  <HeaderNavigationLink onClick={userSignInCallback(auth)}>
                    Login
                  </HeaderNavigationLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      <div className='header__fcb'>fcb</div>
    </header>
  );
};
