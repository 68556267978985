import React, { RefObject } from 'react';
import { Input } from 'semantic-ui-react';
import { FileResult } from '../utils/dopix';

/**
 * Handles to reset the upload file name
 * next to the file upload button.
 * @param fileRef
 * @param setDocumentType
 */
export const resetFileField = (
    fileRef: React.RefObject<Input>,
    setDocumentType: (value: React.SetStateAction<FileResult | null>) => void,
) => {
    if (fileRef?.current) {
        const inputRef = (fileRef?.current as any)
            .inputRef as RefObject<HTMLInputElement>;
        if (inputRef?.current) {
            inputRef.current.value = '';
            setDocumentType(null);
        }
    }
};

/**
 * Handles the file upload progress bar.
 * @param setUploadProgress
 * @param setUploadCompleted
 */
export const fileUploadProgress = (
    setUploadProgress: (value: React.SetStateAction<number | null>) => void,
    setUploadCompleted: (value: React.SetStateAction<boolean>) => void,
) => (percentCompleted: number) => {
    setUploadProgress(percentCompleted);
    if (percentCompleted === 100) {
        setUploadCompleted(true);
    }
};