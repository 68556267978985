export const YES = 'Ja';
export const NO = 'Nein';

export const hp = "\u207A";
export const ae = "\u00E4";
export const oe = "\u00F6";
export const ue = "\u00FC";

export const BOM = '\uFEFF';


export const EMPTY_LINE = ['', '', '', '', '', '', ''];