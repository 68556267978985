import React, { ChangeEvent } from "react";
import { UploadFormData } from "../types/upload-types";

/**
 * Callback function that updates form data when
 * documentEntryPointIds is changed.
 * @param setFormData callback to set form data
 * @param formData form data for upload.
 */
export const onDocumentEntryPointIdsChange = (
    setFormData: (value: React.SetStateAction<UploadFormData>) => void,
    formData: UploadFormData,
) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            documentEntryPointIds: e.target.value,
        });
    };
}

