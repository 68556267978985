export function setProperty(
    object: any,
    propertyName: string,
    toValue: number | string | null) {
    object[propertyName] = toValue;
    return object;
}

export const waitFor = (interval: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, interval));
