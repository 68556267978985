import { get } from './request';
import {
  Diff,
  ImportCheckResult,
  ImportCheckResultDiffed,
  ImportComparison,
  ImportVariable,
} from '../types/import-types';

export type Tenant = {
  title: string;
};

export type Instance = {
  title: string;
  tenants: Tenant[];
};

type DocsAInfo = {
  tenants: {
    name: string;
  }[];
  instanceName: string;
}[];

export const extractError = (e: Error): unknown => {
  try {
    return JSON.parse(e.message);
  } catch {
    return undefined;
  }
};

export const getInstances = async (): Promise<Instance[]> => {
  const results = await get<DocsAInfo>('/docsa/info');
  return results.map(
    (result): Instance => ({
      title: result.instanceName,
      tenants: result.tenants.map(
        (tenant): Tenant => ({
          title: tenant.name,
        }),
      ),
    }),
  );
};

export const checkDiffs = (result: ImportComparison<ImportVariable>): Diff => {
  return Object.entries(result.dopix).reduce((acc, [key, value]) => {
    type Key = keyof typeof result.docsa;
    return {
      ...acc,
      [key]: value !== result.docsa[key as Key],
    };
  }, {} as Diff);
};

export const addDiffsToImportResult = (
  importResult?: ImportCheckResult<ImportVariable>,
): ImportCheckResultDiffed<ImportVariable> | undefined => {
  if (!importResult) {
    return undefined;
  }
  return {
    ...importResult,
    presentInDocsaWithConflict: [
      ...importResult.presentInDocsaWithConflict!.map((result) => {
        const diffs = checkDiffs(result);
        return {
          ...result,
          diffs,
        };
      }),
    ],
  };
};
