import React, { useContext } from 'react';
import { AuthContext } from '../utils';

export const Debug: React.FC = () => {
  const auth = useContext(AuthContext);

  return (
    <div className="debug">
      <h2>Debug</h2>
      <textarea
        cols={50}
        rows={10}
        defaultValue={auth?.user?.signInUserSession.accessToken.jwtToken}
      ></textarea>
    </div>
  );
};
