import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { App } from './components/App';
import { init as initAmplify } from './service/amplify';
import { store } from './store';

import './scss/style.scss';

// noinspection JSIgnoredPromiseFromCall
initAmplify(startApp);

function startApp() {
    const root = createRoot(
        document.getElementById('root') as HTMLElement
    );
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>
    )
}
