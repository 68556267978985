import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { pageCheckedReload } from '../view/reload';

interface MouseEventProps extends PropsWithChildren {
    to?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const HeaderNavigationLink: React.FC<MouseEventProps> =
    ({ to, onClick, children }) => {
        if (onClick) {
            return (
                <button onClick={onClick} className='header__navigation-link'>
                    {children}
                </button>
            );
        }
        if (to) {
            return (
                <NavLink
                    to={to}
                    className={linkState => (linkState.isActive)
                        ? 'header__navigation-link--active'
                        : 'header__navigation-link'}
                    onClick={pageCheckedReload(to)}
                >
                    {children}
                </NavLink>
            );
        }
        return <>{children}</>;
    };