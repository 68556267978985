import React, { SyntheticEvent } from "react";
import { DropdownProps } from "semantic-ui-react";
import { UploadFormData } from "../types/upload-types";

/**
 * Callback function that updates form data when tenant is
 * changed in dropdown.
 * @param setFormData callback to set form data
 * @param formData form data for upload.
 */
export const onTenantChange = (
    setFormData: (value: React.SetStateAction<UploadFormData>) => void,
    formData: UploadFormData,
) => (
    // TODO rename Client to Tenant in all respective places.
    _: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
) => {
    if (data.value) {
        setFormData({
            ...formData,
            tenant: String(data.value),
        });
    }
};