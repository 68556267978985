import { Accordion, Checkbox, Icon, Table } from 'semantic-ui-react';
import { ImportComparisonDiffed, ImportVariable } from '../types/import-types';
import React, { Fragment } from 'react';
import { AccordionVisibility, CompletedState, LABELS } from '../components/upload/ConflictCheckResult';

/**
 * Maps an item of {@link ImportVariable} that is not present
 * in docsA to a table row for user presentation.
 * @param item
 *      A {@link ImportVariable} that shall be
 *      mapped to a table row.
 */
export const notPresentInDocsATableRowMapper = (
    item: ImportVariable,
) => (
    <Table.Row key={`notPresentInDocsa_${item.name}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.type}</Table.Cell>
        <Table.Cell>{item.pattern}</Table.Cell>
        <Table.Cell>{item.defaultValue}</Table.Cell>
        <Table.Cell>{item.dopixPath}</Table.Cell>
        <Table.Cell>
            {item.requiredValue ? (
                <Icon name='checkmark' />
            ) : (
                <Icon name='close' />
            )}
        </Table.Cell>
    </Table.Row>
);

/**
 * Maps an item of {@link ImportVariable} that is present in docsA
 * with no conflict(s) to a table row for user presentation.
 * @param item
 *      A {@link ImportVariable} that is present in docsA
 *      with no conflict(s) that shall be mapped to a table row.
 */
export const presentInDocsAWithNoConflictTableRowMapper = (
    item: ImportVariable,
) => (
    <Table.Row
        key={`presentInDocsaWithNoConflict_${item.name}`}
    >
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.type}</Table.Cell>
        <Table.Cell>{item.pattern}</Table.Cell>
        <Table.Cell>{item.defaultValue}</Table.Cell>
        <Table.Cell>{item.dopixPath}</Table.Cell>
        <Table.Cell>
            {item.requiredValue ? (
                <Icon name='checkmark' />
            ) : (
                <Icon name='close' />
            )}
        </Table.Cell>
    </Table.Row>
);

/**
 * Maps an item of {@link ImportVariable}, where a conflict could be
 * auto handled, to a table row for user presentation.
 * @param item
 *      A {@link ImportVariable}, where a conflict could be
 *      auto handled, that shall be mapped to a table row.
 */
export const autoConflictsHandledTableRowMapper = (
    item: ImportVariable
) => (
    <Table.Row key={`autoConflictsHandled_${item.name}`}>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>{item.type}</Table.Cell>
        <Table.Cell>{item.pattern}</Table.Cell>
        <Table.Cell>{item.defaultValue}</Table.Cell>
        <Table.Cell>{item.dopixPath}</Table.Cell>
        <Table.Cell>
            {item.requiredValue ? (
                <Icon name="checkmark"/>
            ) : (
                <Icon name="close"/>
            )}
        </Table.Cell>
    </Table.Row>
)

/**
 * Handles the conflict check resolve (by radio button) filter.
 * When a user marked an import variable as resolved via radio button
 * then the details view becomes hidden and a green checkmark is added.
 *
 * @param variablesDetailsAccordionVisibility
 * @param setVariablesDetailsAccordionVisibility
 * @param variablesDetailsCompletedState
 * @param setVariablesDetailsCompletedState
 */
export const conflictCheckResolvedFilterRowMapper = (
    variablesDetailsAccordionVisibility: AccordionVisibility,
    setVariablesDetailsAccordionVisibility: React.Dispatch<React.SetStateAction<AccordionVisibility>>,
    variablesDetailsCompletedState: CompletedState,
    setVariablesDetailsCompletedState: React.Dispatch<React.SetStateAction<CompletedState>>,
) =>
    (
        result: ImportComparisonDiffed<ImportVariable>,
        index: number,
    ) => {
        return (
            <Fragment key={`variables_${result.dopix.name}`}>
                <Accordion.Title
                    active={
                        variablesDetailsAccordionVisibility[
                            result.dopix.name
                            ]
                    }
                    onClick={() =>
                        setVariablesDetailsAccordionVisibility({
                            ...variablesDetailsAccordionVisibility,
                            [result.dopix
                                .name]: !variablesDetailsAccordionVisibility[
                                result.dopix.name
                                ],
                        })
                    }
                    className={`
                        conflict-check__accordion-title ${
                        variablesDetailsCompletedState[result.dopix.name]
                            ? 'conflict-check__accordion-title--completed'
                            : ''
                    }`}
                >
                    <Icon
                        name={
                            variablesDetailsCompletedState[result.dopix.name]
                                ? 'check circle'
                                : variablesDetailsAccordionVisibility[
                                    result.dopix.name
                                    ]
                                    ? 'angle down'
                                    : 'angle right'
                        }
                    />{' '}
                    Konflikt {index + 1}: {result.dopix.name}
                    <Checkbox
                        toggle
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                            setVariablesDetailsCompletedState({
                                ...variablesDetailsCompletedState,
                                [result.dopix
                                    .name]: !variablesDetailsCompletedState[
                                    result.dopix.name
                                    ],
                            })
                        }
                        label='Abgeschlossen?'
                        className='conflict-check__accordion-switcher'
                    />
                </Accordion.Title>
                <Accordion.Content
                    active={
                        variablesDetailsAccordionVisibility[
                            result.dopix.name
                            ]
                    }
                >
                    <Table
                        celled
                        striped
                        key={`variable_${result.dopix.name}`}
                        className='conflict-check__table'
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell
                                    className={
                                        result.diffs.name
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {LABELS.variables.name}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className={
                                        result.diffs.type
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {LABELS.variables.type}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className={
                                        result.diffs.pattern
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {LABELS.variables.pattern}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className={
                                        result.diffs.defaultValue
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {LABELS.variables.defaultValue}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className={
                                        result.diffs.dopixPath
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {LABELS.variables.dopixPath}
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className={
                                        result.diffs.requiredValue
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {LABELS.variables.requiredValue}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Dopix</Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.name
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.dopix.name}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.type
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.dopix.type}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.pattern
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.dopix.pattern}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.defaultValue
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.dopix.defaultValue}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.dopixPath
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.dopix.dopixPath}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.requiredValue
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.dopix.requiredValue ? (
                                        <Icon name='checkmark' />
                                    ) : (
                                        <Icon name='close' />
                                    )}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>docsA⁺</Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.name
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.docsa.name}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.type
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.docsa.type}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.pattern
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.docsa.pattern}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.defaultValue
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.docsa.defaultValue}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.dopixPath
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.docsa.dopixPath}
                                </Table.Cell>
                                <Table.Cell
                                    className={
                                        result.diffs.requiredValue
                                            ? 'conflict-check__diff'
                                            : ''
                                    }
                                >
                                    {result.docsa.requiredValue ? (
                                        <Icon name='checkmark' />
                                    ) : (
                                        <Icon name='close' />
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Accordion.Content>
            </Fragment>
        );
    };