import React, { useCallback, useEffect, useState, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { AuthContext } from '../utils';
import { Home } from '../pages/Home';
import { ConflictCheck } from '../pages/ConflictCheck';
import { Debug } from '../pages/Debug';
import { Bye } from '../pages/Bye';
import { Error } from '../pages/Error';
import { Header } from './Header';
import { Main } from './Main';
import { Footer } from './Footer';
import { CognitoUser } from '../service/auth';
import { ProtectedRouteFactory, RouteFactory } from '../utils/ProtectedRoute';
import {
  signInCallBack,
  cognitoSignOutCallback,
  performRefreshStateCallback,
  RefreshStateProps,
} from '../callback/auth-callback';
import { authEffectCallback, setIntervalRefreshEffectCallback } from "../effect/auth-effect-callback";

import './App.scss';

export const App: React.FC = () => {
  const [ready, setReady] = useState<boolean>(false);
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const signIn = useCallback(signInCallBack, []);
  // eslint-disable-next-line
  const refreshState = useCallback(performRefreshStateCallback(
      { setUser, setIsAuthenticated, setReady } as RefreshStateProps), []);
  // eslint-disable-next-line
  const signOut = useCallback(cognitoSignOutCallback(refreshState), [refreshState]);

  useEffect(() =>
      authEffectCallback(refreshState), [refreshState]);

  useLayoutEffect(() =>
      setIntervalRefreshEffectCallback(refreshState), [refreshState]);

  return ready ? (
    <AuthContext.Provider value={{ isAuthenticated, user, signIn, signOut }}>
      <div className="app">
        <Router>
          <Header/>
          <Main>
            <Routes>
              {ProtectedRouteFactory.builder()
                  .path("/")
                  .element(<Home/>)
                  .authorized(isAuthenticated)
                  .build()}
              {ProtectedRouteFactory.builder()
                  .path("/conflict-check")
                  .element(<ConflictCheck/>)
                  .authorized(isAuthenticated)
                  .build()}
              {ProtectedRouteFactory.builder()
                  .path("/debug")
                  .element(<Debug/>)
                  .authorized(isAuthenticated)
                  .build()}
              {RouteFactory.builder()
                  .path("/bye")
                  .element(<Bye/>)
                  .build()}
              {RouteFactory.builder()
                  .path("*")
                  .element(<Error/>)
                  .build()}
            </Routes>
          </Main>
          <Footer/>
        </Router>
      </div>
    </AuthContext.Provider>
  ) : null;
};
