import React from "react";
import { AbstractStep, UploadData } from "./upload-types";
import { ConflictCheckResult } from "../components/upload/ConflictCheckResult";

export interface ConflictCheckApi {
  onComplete: ConflictCheckCompleteHandler;
  submitCallback: Function;
  showOverwriteToggle?: boolean;
}

export interface ConflictCheckFormStep extends ConflictCheckApi {
  data: UploadData;
}

export type ConflictCheckUploadFormData = {
  files?: FileList;
  instance?: string;
  overwriteExistingElements?: boolean;
  tenant?: string;
};

export type ConflictCheckCompleteHandler = (data: UploadData) => void;

export class ConflictCheckResultStep extends AbstractStep {
  render(submitButtonCallback: Function,
         showOverwriteToggle: boolean,
         data: UploadData,
         api: ConflictCheckApi): JSX.Element {
    return <ConflictCheckResult {...api} data={data}
                                submitCallback={submitButtonCallback}/>;
  }
}

export const conflictCheckResultStep = new ConflictCheckResultStep(
  'info',
  'Ergebnis',
  'Ergebnis des Imports',
  undefined
);
